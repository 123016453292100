import React, { Component } from 'react'
import './contact.css'

class ContactForm extends Component {
  state = {
    returning: false,
  }

  constructor(props) {
    super(props)
    if (typeof window !== `undefined`) {
      const uPathName = window.location.href
      if (/thanks/.test(uPathName)) {
        this.state.returning = true
      }
    }
  }

  render() {
    const returningMessage = (
      <div className="message text-center">
        <h2>Thank you! We will be in touch shortly.</h2>
      </div>
    )

    return (
      <div className="contactFormWrapper">
        <div className="contactForm">
          {this.state.returning ? (
            returningMessage
          ) : (
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/contact#thanks"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p style={{ display: 'none' }}>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <input
                name="name"
                placeholder="Name"
                autoComplete="name"
                type="text"
                required
              />
              <input
                name="_replyto"
                type="email"
                autoComplete="email"
                required
                placeholder="Email Address"
              />
              <input
                name="phone"
                type="text"
                placeholder="Phone"
                autoComplete="tel-national"
              />
              <input
                name="address"
                type="text"
                placeholder="Address"
                autoComplete="address"
              />
              <textarea
                name="message"
                required
                rows={5}
                autoComplete="off"
                placeholder="Message"
              />
              <input
                className="btn btn-blue sendForm"
                type="submit"
                value="Send"
              />
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default ContactForm
