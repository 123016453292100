import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import ContactForm from '../components/contact-form'
import Map from '../components/map'

const Contact = () => (
  <Layout>
    <Helmet title="Talk to us" />
    <section className="contact container">
      <div>
        <h1>Talk to us</h1>
        <hr />
        <p>101 BRADFIELD ED LINDFIELD NSW 2070.</p>
        <p>
          Phone: <a href="tel:1300141413">1300 14 14 13</a>
        </p>
        <p>Monday – Friday: 9AM to 5:30PM</p>
        <p>Sat – Sunday: 9AM to 5:00PM</p>
        <p>
          <a href="mailto:info@sydneypoolrenovations.com.au">
            info@sydneypoolrenovations.com.au
          </a>
        </p>
      </div>
      <div>
        <h1>Get in Touch</h1>
        <hr />
        <ContactForm address="samgareth@gmail.com" />
      </div>
    </section>
    <div className="map">
      <Map />
    </div>
  </Layout>
)

export default Contact
