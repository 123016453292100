import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      zoom={15}
      center={{ lat: -33.785457, lng: 151.1481493 }}
      defaultOptions={{
        scrollwheel: false
      }}
    >
      <Marker position={{ lat: -33.785457, lng: 151.1481493 }} />
    </GoogleMap>
  ))
)

const Map = props => (
  <MapWithAMarker
    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDlPaQ_6oVz5PzXPrLeKKnTgcWww0JN158&v=3.exp'
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `400px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
)

export default Map
